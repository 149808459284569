






































import Navbar from "components/Navbar.vue";
import { Component, Vue } from "vue-property-decorator";
import Container from "views/Container.vue";
import { Getter } from "vuex-class";

@Component({ components: { Navbar, Container } })
export default class App extends Vue {
  @Getter currentUser;

  get showCredits() {
    return !this.publicRoutes;
  }

  get showDisclaimer() {
    return (
      !this.publicRoutes &&
      (this.currentUser?.isDoctor || this.currentUser?.isNurse)
    );
  }

  get publicRoutes() {
    return this.$route.name === "diary" || this.$route.name === "diary_print";
  }

  get backgroundClass() {
    if (this.publicRoutes) {
      return;
    } else {
      return this.currentUser ? "logged" : "not-logged";
    }
  }
}

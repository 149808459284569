






import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { RequestData } from "store/types/RequestStore";

@Component
export default class DisplayErrors extends Vue {
  @Prop() tag!: string;

  @Getter("status", { namespace: "http" }) status: {
    [key: string]: RequestData;
  };

  get errors() {
    return (
      this.status[this.tag].statusCode === 422 && this.status[this.tag].errors
    );
  }
}

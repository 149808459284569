import VueRouter, { RouteConfig } from "vue-router";
import store from "store";
import User from "models/User";
import AuthenticationRoutes from "router/Authentication";
import StyleguideRoutes from "router/Styleguide";
import AdminRoutes from "router/Admin";
import DoctorRoutes from "router/Doctor";

// Roles: ['superadmin', 'admin', 'doctor', 'nurse']

const routes: RouteConfig[] = [
  { path: "/", redirect: "secure", name: "root", meta: { role: ["anyone"] } },
  {
    path: "/blog",
    name: "blog",
    meta: { role: ["anyone"] },
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/BlogAuth.vue"),
  },
  {
    path: "/blog/*",
    name: "blog_paths",
    meta: { role: ["anyone"] },
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/BlogAuth.vue"),
  },
  {
    path: "/secure",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/Container.vue"),
    meta: { role: ["doctor", "nurse", "admin", "superadmin"] },
    children: [
      {
        path: "/",
        redirect: "dashboard",
        name: "secure_root",
        meta: { role: ["superadmin", "admin"] },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: 'doctor' */ "views/Dashboard.vue"),
        meta: { role: ["superadmin", "admin"] },
      },
      ...AdminRoutes,
      ...StyleguideRoutes,
      ...DoctorRoutes,
    ],
  },
  {
    path: "/diary/:token",
    name: "diary",
    component: () =>
      import(/* webpackChunkName: 'diary' */ "views/Public/Diary.vue"),
    meta: { noAuth: true },
  },
  {
    path: "/diary_print/:token",
    name: "diary_print",
    component: () =>
      import(/* webpackChunkName: 'diary' */ "views/Public/DiaryPrint.vue"),
    meta: { noAuth: true },
  },
  {
    path: "/forgot_password",
    component: () =>
      import(/* webpackChunkName: "auth" */ "views/Password/Forgot.vue"),
    name: "forgot_password",
    meta: { noAuth: true },
  },
  {
    path: "/requests",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/Container.vue"),
    meta: { noAuth: true },
    children: [
      {
        name: "new_request",
        path: "",
        meta: { noAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'subscription' */ "views/SubscriptionRequest/Create.vue"
          ),
      },
      {
        name: "validate_request",
        path: "validate",
        meta: { noAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'subscription' */ "views/SubscriptionRequest/Confirmation.vue"
          ),
      },
    ],
  },
  {
    path: "/reset_password",
    component: () =>
      import(/* webpackChunkName: 'auth' */ "views/Password/Reset.vue"),
    name: "reset_password",
    meta: { noAuth: true },
  },
  ...AuthenticationRoutes,
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/NotFound.vue"),
    name: "404",
    meta: { role: ["anyone"] },
  },
];

const router = new VueRouter({
  base: "/",
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const { role, noAuth } = to?.matched[to?.matched.length - 1]?.meta || {};
  if (to?.path == "/logout") {
    next();
    return;
  }
  store.dispatch("authenticate").then(
    (user: User) => {
      if (to?.matched?.some((record) => record.meta.noAuth)) {
        next("/");
        return;
      }

      if (user.isDoctor || user.isNurse) {
        if (to?.matched?.some((record) => record.path == "/secure/dashboard")) {
          next("/secure/patients");
          return;
        }
      }

      if (user.isAdmin || user.isSuperAdmin) {
        if (to?.matched?.some((record) => record.path == "/secure/dashboard")) {
          next("/secure/subscription_requests");
          return;
        }
      }

      if (role?.includes(user.role) || role?.includes("anyone")) {
        // if authorized
        next();
        return;
      } else {
        if (user.isDoctor || user.isNurse) {
          next("/secure/patients");
          return;
        } else {
          next(false);
        }

        return;
      }
    },
    () => {
      if (to?.matched?.every((record) => record.meta.noAuth)) {
        next();
        return;
      } else {
        next("/login");
      }
    }
  );
});

export default router;

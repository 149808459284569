import Parser from "./Parser";

export default class Model {
  id: any;
  static type: string | undefined;

  constructor(data, included = []) {
    this.id = data.id;
    for (const key in data.attributes) {
      this[key] = data.attributes[key];
    }

    for (const key in data.relationships) {
      const relation = data.relationships[key];
      if (relation) {
        this[key] = Parser.parse(relation, included);
      }
    }
  }

  toJSON(): any {
    let result = { ...this };
    return result;
  }

  toFormData() {
    const data = this.toJSON();
    const formData = new FormData();
    for (let key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        if (Array.isArray(data[key])) {
          for (const value of data[key]) {
            formData.append(key + "[]", value);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    return formData;
  }
}

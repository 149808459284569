const routes = [
  {
    path: "facilities",
    component: () =>
      import(/* webpackChunkName: "admin" */ "views/Facility/Main.vue"),
    meta: { role: ["anyone"] },
    children: [
      { path: "/", redirect: "list", meta: { role: ["admin", "superadmin"] } },
      {
        path: "list",
        name: "facility_list",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "views/Facility/TableList.vue"
          ),
        meta: { role: ["anyone"] },
      },
    ],
  },
  {
    path: "users",
    component: () =>
      import(/* webpackChunkName: "admin" */ "views/User/Main.vue"),
    meta: { role: ["anyone"] },
    children: [
      { path: "/", redirect: "list", meta: { role: ["admin", "superadmin"] } },
      {
        path: "list",
        name: "user_list",
        component: () =>
          import(/* webpackChunkName: "admin" */ "views/User/TableList.vue"),
        meta: { role: ["anyone"] },
      },
    ],
  },
  {
    path: "subscription_requests",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "views/SubscriptionRequest/Main.vue"
      ),
    meta: { role: ["anyone"] },
    children: [
      { path: "/", redirect: "list", meta: { role: ["admin", "superadmin"] } },
      {
        path: "list",
        name: "subscription_request_list",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "views/SubscriptionRequest/TableList.vue"
          ),
        meta: { role: ["anyone"] },
      },
    ],
  },
  {
    path: "algorithms",
    component: () =>
      import(/* webpackChunkName: "admin" */ "views/Algorithm/Main.vue"),
    meta: { role: ["admin", "superadmin", "doctor"] },
    children: [
      {
        path: "/",
        name: "algorithm",
        redirect: "list",
        meta: { role: ["admin", "superadmin", "doctor"] },
      },
      {
        path: "list",
        name: "algorithm_list",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "views/Algorithm/TableList.vue"
          ),
        meta: { role: ["admin", "superadmin", "doctor"] },
      },
    ],
  },
];

export default routes;

import Model from "./Model";
import ICity from "./interfaces/ICity";

export default class City extends Model implements ICity {
  static type = "cities";

  name: string;
  province: string;

  format(): string {
    return `${this.name} (${this.province})`;
  }
}

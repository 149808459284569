const routes = [
  {
    path: "create_diary",
    name: "create_diary",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Diary/Main.vue"),
    meta: { role: ["doctor"] },
  },
  {
    path: "create_diary/:id",
    name: "create_diary_of_patient",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Diary/Main.vue"),
    meta: { role: ["doctor"] },
  },
  {
    path: "patients",
    name: "patients",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Patient/Main.vue"),
    meta: { role: ["doctor", "nurse"] },
  },
  {
    path: "patients/:id",
    name: "patient",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Patient/Show.vue"),
    meta: { role: ["doctor", "nurse"] },
  },
  {
    path: "posts",
    name: "posts",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Post/Main.vue"),
    meta: { role: ["doctor", "nurse", "admin", "superadmin"] },
  },
  {
    path: "posts/:id",
    name: "post",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Post/Show.vue"),
    meta: { role: ["doctor", "nurse", "admin", "superadmin"] },
  },
  {
    path: "create_post",
    name: "create_post",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Post/Create.vue"),
    meta: { role: ["doctor", "nurse"] },
  },
];

export default routes;

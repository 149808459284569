import Model from "./Model";
import User from "./User";
import Facility from "./Facility";
import IAlgorithm from "./interfaces/IAlgorithm";

export enum AlgorithmType {
  Global,
  Facility,
}
export class AlgorithmEntry {
  min: number;
  max: number;
  dose: number;
}

export default class Algorithm extends Model implements IAlgorithm {
  static type = "algorithms";

  name: string;
  doctor?: User;
  facility?: Facility;
  frequency: number;
  status: string;
  configuration: AlgorithmEntry[];

  get isFacility() {
    return this.facility?.id !== undefined;
  }
}

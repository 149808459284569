import { Module, MutationTree, GetterTree } from "vuex";
import RootState from "./types/RootState";
import { ActionTree } from "vuex";
import DiaryState from "store/types/DiaryState";
import { Patient, Tritation } from "models";
import Diary from "models/Diary";
const Routes = require("../utils/RailsRoutes");

export const state: DiaryState = {
  diary: undefined,
};

export const getters: GetterTree<DiaryState, RootState> = {
  diary(state): Diary | undefined {
    return state.diary;
  },
};

export const mutations: MutationTree<DiaryState> = {
  setDiary(state, payload: Diary | undefined) {
    state.diary = payload;
  },
  updateDiaryTritation(state, payload: Tritation) {
    const value = state.diary.tritations.find((o) => o.id === payload.id);
    if (!value) {
      return;
    }
    for (const key in payload) {
      if (key !== "id") {
        (value as any)[key] = (payload as any)[key];
      }
    }
  },
};

export const actions: ActionTree<DiaryState, RootState> = {
  setDiary({ commit }, payload: Diary | undefined) {
    commit("setDiary", payload);
  },
  updateDiaryTritation({ commit }, payload: Tritation) {
    commit("updateDiaryTritation", payload);
  },
};

export const diary: Module<DiaryState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

import Model from "./Model";
import IUser from "./interfaces/IUser";
import Facility from "./Facility";

export default class User extends Model implements IUser {
  static type = "users";
  static roles: Array<{ name: string; id: string }> = [
    { name: "SuperAdmin", id: "superadmin" },
    { name: "Admin", id: "admin" },
    { name: "Medico", id: "doctor" },
    { name: "Infermiere", id: "nurse" },
  ];

  email: string;
  firstName: string;
  lastName: string;
  role: string;
  active: boolean;
  facility: Facility;

  fullname(): string {
    return `${this.isDoctor ? "Dr. " : ""}${this.firstName} ${this.lastName}`;
  }

  static getRoleLabel(role: string): string | undefined {
    return this.roles.find((o) => o.id === role)?.name;
  }

  isOneOf(roles: string[]) {
    return roles.includes(this.role);
  }

  get isAdmin() {
    return this.role === "admin";
  }

  get isSuperAdmin() {
    return this.role === "superadmin";
  }

  get isDoctor() {
    return this.role === "doctor";
  }

  get isNurse() {
    return this.role === "nurse";
  }
}

import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component
export default class DrugsMixin extends Vue {
  createRead(data: any) {
    return axios({
      url: this.$urlFor("api_v1_reads"),
      data: data,
      method: "POST",
    });
  }

  missingRead() {
    return axios({
      url: this.$urlFor("posts_missing_api_v1_reads"),
      method: "GET",
    });
  }
}

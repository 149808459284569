import Model from "./Model";
import * as models from ".";

export default class Parser {
  static parse(data, included = []) {
    if (data == null) {
      return null;
    }
    if (Array.isArray(data)) {
      return Parser.parseList(data, included);
    } else if (data.data && !data.id) {
      return Parser.parse(data.data, data.included || included);
    } else {
      return Parser.parseElement(data, included);
    }
  }

  static parseList(list, included) {
    return list.map((e) => {
      return Parser.parseElement(e, included);
    });
  }

  static parseElement(element, included) {
    element = Parser.load(element, included);
    const model = Object.values(models).filter((e) => e.type === element.type);
    return new (model[0] || Model)(element, included);
  }

  static load(element, included) {
    return (
      included.find((e) => e.id == element.id && e.type === element.type) ||
      element
    );
  }
}




































































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { User } from "../models";

@Component
export default class SideBar extends Vue {
  @Getter currentUser?: User;

  private publicMenuItems: [
    { name: string; to: string; activeRoutes: string[]; icon: string }
  ] = [
    {
      name: "Dashboard",
      to: "dashboard",
      activeRoutes: ["dashboard"],
      icon: "gamepad",
    },
  ];

  private privateMenuItems: {
    name: string;
    to: string;
    activeRoutes: string[];
    icon: string;
  }[] = [
    {
      name: "Utenti",
      to: "user_list",
      activeRoutes: ["users", "user_list"],
      icon: "users",
    },
    {
      name: "Richiesta",
      to: "subscription_request_list",
      activeRoutes: ["subscription_requests", "subscription_request_list"],
      icon: "users",
    },
    {
      name: "Strutture",
      to: "facility_list",
      activeRoutes: ["facilities", "facility_list"],
      icon: "users",
    },
    {
      name: "Algoritmi",
      to: "algorithm_list",
      activeRoutes: ["algorithms", "algorithm_list"],
      icon: "users",
    },
  ];

  get canShowTables() {
    return this.currentUser?.isAdmin || this.currentUser?.isSuperAdmin;
  }
}

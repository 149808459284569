export default {
  methods: {
    $isRouteActive(route) {
      return !!this.$route.matched.find((e) => e.name === route);
    },
    $areRouteActive(routes) {
      return !!this.$route.matched.some((e) => routes.includes(e.name));
    },
  },
};

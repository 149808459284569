import Model from "./Model";
import IPatient from "models/interfaces/IPatient";
import Facility from "models/Facility";
import Diary from "./Diary";
import { User } from "models";

export default class Patient extends Model implements IPatient {
  static type = "patients";

  firstName: string;
  lastName: string;
  code: string;
  birthYear: string;
  trainedAt: Date;
  trainedBy: User;
  facility: Facility;
  diaries: Diary[];
  notes: string;

  get fullname() {
    return `${this.firstName} ${this.lastName}`;
  }

  get initials() {
    return `${this.firstName[0]}${this.lastName[0]}`;
  }

  get lastDiary() {
    let toSort = [...this.diaries];

    toSort.sort((a: Diary, b: Diary) => {
      return (
        this.getTime(new Date(b.createdAt)) -
        this.getTime(new Date(a.createdAt))
      );
    });

    return toSort[0];
  }

  sortDiaries() {
    this.diaries.sort((a: Diary, b: Diary) => {
      return (
        this.getTime(new Date(b.createdAt)) -
        this.getTime(new Date(a.createdAt))
      );
    });
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }
}

import Model from "./Model";
import Facility from "./Facility";
import ISubscriptionRequest from "./interfaces/ISubscriptionRequest";
import City from "./City";

export class DiaryStatus {
  id: string;
  label: string;
}

export default class SubscriptionRequest
  extends Model
  implements ISubscriptionRequest
{
  static type = "subscriptionRequests";

  static statuses: DiaryStatus[] = [
    { id: "sent", label: "Da evadere" },
    { id: "rejected", label: "Rifiutate" },
    { id: "accepted", label: "Accettate" },
  ];

  firstName: string;
  lastName: string;
  email: string;
  facilityName: string;
  password: string;
  status: string;
  createdAt: Date;
  passwordConfirmation: string;
  role: "doctor" | "nurse";
  city: City;
  facility: Facility;

  static roles: Array<{ name: string; id: string }> = [
    { name: "SuperAdmin", id: "superadmin" },
    { name: "Admin", id: "admin" },
    { name: "Medico", id: "doctor" },
    { name: "Infermiere", id: "nurse" },
  ];

  get fullname(): string {
    return this.firstName + " " + this.lastName;
  }

  get processed() {
    return this.accepted || this.rejected;
  }

  get accepted() {
    return this.status === "accepted";
  }

  get rejected() {
    return this.status === "rejected";
  }

  get sent() {
    return this.status === "sent";
  }

  toData() {
    if (this.facility.id === null) {
      return {
        ...this,
        city_id: this.city.id,
        facilityName: this.facility.name,
      };
    } else {
      return { ...this, city_id: this.city.id, facility_id: this.facility.id };
    }
  }
}

const Routes = require("../utils/RailsRoutes");

export default {
  methods: {
    $railsRouter() {
      return Routes;
    },
    $urlFor(name, ...args) {
      const route = Routes[`${name}_path`];
      try {
        return route(...args);
      } catch (e) {
        if (route) {
          console.error(
            `There was a problem with route "${name}" resolved as "${
              Routes[`${name}_path`]
            }" with args ${JSON.stringify(args)}:`
          );
        } else {
          console.error(
            `No such route "${name}" with args ${JSON.stringify(args)}`
          );
        }
        console.error(e);
      }
    },
  },
};

import Model from "./Model";
import ITritation from "./interfaces/ITritation";

export default class Tritation extends Model implements ITritation {
  static type = "tritations";

  startDate: Date;
  endDate: Date;
  startValue: number;
  endValue: number;
  dose?: number;
  suggestedDose?: number;
}


































































































































import { Vue, Mixins } from "vue-property-decorator";
import Component from "vue-class-component";
import { User } from "models";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import store from "store";
import ClickOutside from "directives/ClickOutside";
import ReadsMixin from "mixins/http/ReadsMixin";

const forum = namespace("forum");
@Component({
  directives: { ClickOutside },
})
export default class Navbar extends Mixins(ReadsMixin) {
  @Getter currentUser;
  @Getter token;
  @forum.Getter unreadPosts: number;
  @forum.Action setUnreadPosts: (count: number) => void;

  dropdownOpen: boolean = false;
  notificationsOpen: boolean = false;

  mounted() {
    if (this.currentUser) {
      this.checkUnreadPosts();
    }
  }

  checkUnreadPosts() {
    this.missingRead().then((resp) => {
      this.setUnreadPosts(resp.data.missing);
    });
  }

  get links() {
    if (this.currentUser.isNurse) {
      let items = [
        {
          title: "Pazienti e diari",
          name: "patients",
        },
        {
          title: "Esperienze",
          name: "posts",
          badge: true && this.unreadPosts > 0,
          value: this.unreadPosts,
        },
      ];
    } else if (this.currentUser.isDoctor) {
      return [
        {
          title: "Pazienti e diari",
          name: "patients",
        },
        {
          title: "Algoritmi",
          name: "algorithm_list",
        },
        {
          title: "Esperienze",
          name: "posts",
          badge: true && this.unreadPosts > 0,
          value: this.unreadPosts,
        },
      ];
    } else if (this.currentUser.isSuperAdmin || this.currentUser.isAdmin) {
      return [
        {
          title: "Richieste",
          name: "subscription_request_list",
        },
        {
          title: "Algoritmi",
          name: "algorithm_list",
        },
        {
          title: "Utenti",
          name: "user_list",
        },

        {
          title: "Strutture",
          name: "facility_list",
        },

        {
          title: "Esperienze",
          name: "posts",
          badge: true && this.unreadPosts > 0,
          value: this.unreadPosts,
        },
      ];
    }
  }

  get userRole() {
    return this.currentUser ? User.getRoleLabel(this.currentUser.role) : "";
  }

  _logout() {
    this.$router.push({ path: "/logout" }).catch(() => {});
  }

  onLoginClicked() {
    this.$router.push({ name: "login" });
  }

  onOutsideClick() {
    this.dropdownOpen = false;
  }
}












import { Vue, Component } from "vue-property-decorator";
import Navbar from "components/Navbar.vue";
import Sidebar from "components/Sidebar.vue";
import { Getter } from "vuex-class";
import { User } from "models";
@Component({ components: { Sidebar, Navbar } })
export default class Container extends Vue {
  @Getter currentUser?: User;
}

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faPaintBrush,
  faEllipsisV,
  faChevronUp,
  faPalette,
  faBold,
  faItalic,
  faFont,
  faTrash,
  faPlus,
  faUsers,
  faShoppingCart,
  faFlask,
  faCube,
  faGamepad,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faFile, faImage } from "@fortawesome/free-regular-svg-icons";

import {} from "@fortawesome/free-brands-svg-icons";

library.add(
  faPalette,
  faPaintBrush,
  faFont,
  faChevronUp,
  faBold,
  faItalic,
  faChevronDown,
  faTrash,
  faPlus,
  faEllipsisV,
  faUsers,
  faShoppingCart,
  faFlask,
  faCube,
  faGamepad,
  faChevronLeft,
  faChevronRight
);

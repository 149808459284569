import { Module, MutationTree, GetterTree } from "vuex";
import RootState from "./types/RootState";
import { ActionTree } from "vuex";
import CurrentPatientStore from "store/types/CurrentPatientState";
import { Patient, Tritation } from "models";
import Diary from "models/Diary";
const Routes = require("../utils/RailsRoutes");

export const state: CurrentPatientStore = {
  patient: undefined,
  diary: undefined,
};

export const getters: GetterTree<CurrentPatientStore, RootState> = {
  patient(state): Patient | undefined {
    return state.patient;
  },

  diary(state): Diary | undefined {
    return state.diary;
  },
};

export const mutations: MutationTree<CurrentPatientStore> = {
  setPatient(state, payload: Patient | undefined) {
    state.patient = payload;
  },
  setDiary(state, payload: Diary | undefined) {
    state.diary = payload;
  },
  updateDiaryTritation(state, payload: Tritation) {
    const value = state.diary.tritations.find((o) => o.id === payload.id);
    if (!value) {
      return;
    }
    for (const key in payload) {
      if (key !== "id") {
        (value as any)[key] = (payload as any)[key];
      }
    }
  },
};

export const actions: ActionTree<CurrentPatientStore, RootState> = {
  setPatient({ commit }, payload: Patient | undefined) {
    payload.sortDiaries();
    commit("setPatient", payload);
    commit("setDiary", payload.lastDiary);
  },
  setDiary({ commit }, payload: Diary | undefined) {
    commit("setDiary", payload);
  },
  updateDiaryTritation({ commit }, payload: Tritation) {
    commit("updateDiaryTritation", payload);
  },
};

export const currentPatient: Module<CurrentPatientStore, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

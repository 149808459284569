import Vuex, { StoreOptions, Module, MutationTree, GetterTree } from "vuex";
import RootState from "./types/RootState";
import { ActionTree } from "vuex";
import User from "models/User";
import ForumState from "./types/ForumState";
import axios from "axios";
import Parser from "models/Parser";
const Routes = require("../utils/RailsRoutes");

export const state: ForumState = {
  unreadPosts: 0,
};

export const getters: GetterTree<ForumState, RootState> = {
  unreadPosts(state): number {
    return state.unreadPosts;
  },
};

export const mutations: MutationTree<ForumState> = {
  setUnreadPosts(state, payload: number) {
    state.unreadPosts = payload;
  },
};

export const actions: ActionTree<ForumState, RootState> = {
  setUnreadPosts({ commit }, payload) {
    commit("setUnreadPosts", payload);
  },
};

export const forum: Module<ForumState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

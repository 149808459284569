//
import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import * as Integrations from '@sentry/integrations';

Sentry.init({
   dsn: "https://304ea7d09c5848ce999945f6e669ff8f@sentry.monade.io/22",
   blacklistUrls: [
     // Facebook flakiness
     /graph\.facebook\.com/i,
     // Facebook blocked
     /connect\.facebook\.net\/en_US\/all\.js/i,
     // Chrome extensions
     /extensions\//i,
     /^chrome:\/\//i
   ],
   integrations: [
     new Integrations.Vue({
       Vue,
       attachProps: true,
     }),
   ],
 });
 //

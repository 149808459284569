import { Module, MutationTree, GetterTree } from "vuex";
import RootState from "./types/RootState";
import { ActionTree } from "vuex";
import { RequestStore, RequestData } from "./types/RequestStore";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Parser from "models/Parser";
import Vue from "vue";
const Routes = require("../utils/RailsRoutes");

export const state: RequestStore = {
  status: {},
};

export const getters: GetterTree<RequestStore, RootState> = {
  status(state): { [key: string]: RequestData } {
    return state.status;
  },
};

export const mutations: MutationTree<RequestStore> = {
  register(state, payload: string) {
    if (state.status[payload]) {
      return;
    }
    Vue.set(state.status, payload, {
      loading: false,
      statusCode: 0,
      errors: null,
    });
  },
  clear(state, payload: string) {
    Vue.set(state.status, payload, {
      loading: false,
      statusCode: 0,
      errors: null,
    });
  },
  setError(state, payload: { tag: string; errors: any }) {
    Vue.set(state.status[payload.tag], "errors", payload.errors);
  },
  setResponseData(
    state,
    {
      tag,
      response,
      isError,
    }: { tag: string; response: AxiosResponse<any>; isError: boolean }
  ) {
    state.status[tag].statusCode = response?.status || -1;
    if (!isError) {
      Vue.set(state.status[tag], "meta", response.data.meta);
      return;
    }

    if (response?.data?.errors) {
      Vue.set(state.status[tag], "errors", response.data);
    } else {
      Vue.set(state.status[tag], "errors", {
        message: response?.statusText || "Unknown Error",
      });
    }
  },
  setLoading(state, payload: { tag: string; loading: boolean }) {
    state.status[payload.tag].loading = payload.loading;
  },
};

export const actions: ActionTree<RequestStore, RootState> = {
  register({ commit }, payload: string) {
    commit("register", payload);
  },

  clear({ commit }, payload: string) {
    commit("clear", payload);
  },

  request({ commit, getters }, config: AxiosRequestConfig & { tag: string }) {
    const { tag } = config;
    commit("setLoading", { tag, loading: true });
    return axios(config)
      .then(
        (response) => {
          commit("setResponseData", { tag, response, isError: false });
          return Parser.parse(response.data);
        },
        (err) => {
          const { response } = err;
          commit("setResponseData", { tag, response, isError: true });
          return Promise.reject(err);
        }
      )
      .finally(() => commit("setLoading", { tag, loading: false }));
  },
};

export const http: Module<RequestStore, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vuex, { Module, MutationTree, GetterTree } from "vuex";
import RootState from "./types/RootState";
import { ActionTree } from "vuex";
import DoctorSettingsStore from "./types/DoctorSettingsState";
const Routes = require("../utils/RailsRoutes");

export const state: DoctorSettingsStore = {
  tritationStartDayOfWeek: "-1",
  algorithmId: 0,
};

export const getters: GetterTree<DoctorSettingsStore, RootState> = {
  currentTritationStartDayOfWeek(state): string | undefined {
    return state.tritationStartDayOfWeek;
  },
  currentAlgorithmId(state): number | undefined {
    return state.algorithmId;
  },
};

export const mutations: MutationTree<DoctorSettingsStore> = {
  setCurrentTritationStartDayOfWeek(state, payload: string | undefined) {
    state.tritationStartDayOfWeek = payload;
  },
  setCurrentAlgorithmId(state, payload: number | undefined) {
    state.algorithmId = payload;
  },
};

export const actions: ActionTree<DoctorSettingsStore, RootState> = {
  updateCurrentTritationStartDayOfWeek({ commit }, option: string = "") {
    commit("setCurrentTritationStartDayOfWeek", option);
  },
  updateCurrentAlgorithmId({ commit }, option: number = 0) {
    commit("setCurrentAlgorithmId", option);
  },
};

export const doctorSettings: Module<DoctorSettingsStore, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

import Vue from "vue";
import VueMoment from "vue-moment";
import * as moment from "moment";
import "moment/locale/it";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";

moment.locale("it");

Vue.use(VueMoment, {
  moment,
});

const datepickerOptions = {
  sundayFirst: false,
  days: moment.weekdays(true),
  daysShort: moment.weekdaysShort(true),
  monthNames: moment.months(),
  texts: {
    cancel: "Chiudi",
    apply: "Seleziona",
    keyboardShortcuts: "Comandi da tastiera",
  },
  keyboardShortcuts: [
    {
      symbol: "↵",
      label: "Seleziona la data attiva",
      symbolDescription: "Invio",
    },
    {
      symbol: "←/→",
      label: "Muovi avanti (destra) o indietro (sinistra) di un giorno.",
      symbolDescription: "Frecce sinistra/destra",
    },
    {
      symbol: "↑/↓",
      label: "Muovi avanti (sopra) o indietro (sotto) di una settimana.",
      symbolDescription: "Frecce su/giù",
    },
    { symbol: "PgUp/PgDn", label: "Cambia mese.", symbolDescription: "PagSu" },
    {
      symbol: "Home/End",
      label: "Vai al primo giorno del mese",
      symbolDescription: "Tasto Home o End",
    },
    { symbol: "Esc", label: "Chiudi", symbolDescription: "Esc" },
    { symbol: "?", label: "Apri", symbolDescription: "Punto di domada" },
  ],
};

Vue.use(AirbnbStyleDatepicker, datepickerOptions);

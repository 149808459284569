import Model from "./Model";
import IDrug from "models/interfaces/IDrug";

export default class Drug extends Model implements IDrug {
  static type = "drugs";

  brand: string;
  privateBrand: string;
  name: string;
  image: string;
  maxDose: number;
  trainingUrl: string;
}

import Model from "./Model";
import IDiary from "models/interfaces/IDiary";
import Patient from "./Patient";
import Drug from "./Drug";
import Tritation from "./Tritation";
import { AlgorithmEntry } from "./Algorithm";

export class DiaryStatus {
  id: string;
  label: string;
}

export default class Diary extends Model implements IDiary {
  static type = "diaries";

  static statuses: DiaryStatus[] = [
    { id: "closed", label: "Archiviato" },
    { id: "active", label: "In corso" },
  ];

  prescriptionDose: number;
  prescriptionTime: string;
  therapyStart: Date;
  status: string;
  drug: Drug;
  patient: Patient;
  createdAt: Date;
  algorithm: Algorithm;
  tritations: Tritation[];
  tritationStart: Date;
  therapyEnd: Date;
  frequency: number;
  pdf: string;
  configuration: AlgorithmEntry[];

  static statusLabel(status: string): string | undefined {
    return this.statuses.find((o) => o.id === status)?.label;
  }

  completedTritations() {
    return this.tritations?.filter((o) => o.dose)?.length || 0;
  }

  therapyProgress() {
    return (this.completedTritations() * 100) / this.tritations.length;
  }

  disabledTritations() {
    let notCompletedIds = [
      ...this.tritations?.filter((o) => !o.dose).map((o) => o.id),
    ];
    notCompletedIds.shift();
    return notCompletedIds;
  }
}






import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ name: "icon" })
export default class VIcon extends Vue {
  @Prop({ default: false }) spin?: boolean;

  getIcon() {
    return this.$slots.default && this.$slots.default.length
      ? this.$slots.default[0].text
      : null;
  }

  getSvg() {
    return this.getIcon()
      ? require(`!svg-inline-loader!../assets/icons/${this.getIcon()}.svg`)
      : null;
  }
}

import Vuex, { StoreOptions, Module, MutationTree, GetterTree } from "vuex";
import AuthState from "./types/AuthState";
import RootState from "./types/RootState";
import { ActionTree } from "vuex";
import User from "models/User";
import axios from "axios";
import Vue from "vue";

const Routes = require("../utils/RailsRoutes");

export const state: AuthState = {
  token: undefined,
};

export const getters: GetterTree<AuthState, RootState> = {
  token(state) {
    return state.token;
  },
};

export const mutations: MutationTree<AuthState> = {
  authSuccess(state, payload: string) {
    state.token = payload;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.getters.token}`;
  },
  clearAuth(state) {
    state.token = undefined;
    Vue.$cookies.set("blog_auth", "");
    delete axios.defaults.headers.common["Authorization"];
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  login(
    { commit },
    user: { email: string; password: string }
  ): Promise<User | undefined> {
    commit("setCurrentUser", undefined);
    commit("clearAuth");

    return axios({
      url: Routes.user_session_path({ format: "json" }),
      data: { user },
      method: "POST",
    }).then(
      (resp) => {
        const [_, token] = (resp.headers.authorization || " ").split(" ");
        return this.dispatch("authenticate", token);
      },
      (err) => Promise.reject(undefined)
    );
  },
  logout({ commit }) {
    commit("setCurrentUser", undefined);
    commit("clearAuth");
  },
  authenticate(
    { commit, rootGetters, dispatch },
    token?: string
  ): Promise<User | undefined> {
    token = token || this.getters.token;
    if (!token) {
      return Promise.reject(undefined);
    }

    if (rootGetters.currentUser instanceof User) {
      return Promise.resolve(rootGetters.currentUser);
    }

    return dispatch("loadCurrentUser", {
      headers: { Authorization: `Bearer ${token}` },
    }).then(
      (user) => {
        commit("authSuccess", token);
        return Promise.resolve(user);
      },
      (error) => {
        commit("clearAuth");
        return Promise.reject(undefined);
      }
    );
  },
};

export const auth: Module<AuthState, RootState> = {
  getters,
  mutations,
  actions,
};

import { Component, Vue } from "vue-property-decorator";

@Component
export default class RequestsMixin extends Vue {
  $httpGet(url: string, tag: string) {
    return this.$store.dispatch("http/request", {
      tag: tag,
      url: url,
      method: "GET",
    });
  }

  public $httpPost(url: string, tag: string, data) {
    return this.$store.dispatch("http/request", {
      tag: tag,
      url: url,
      data: data,
      method: "POST",
    });
  }

  public $httpPut(url: string, tag: string, data) {
    return this.$store.dispatch("http/request", {
      tag: tag,
      url: url,
      data: data,
      method: "PUT",
    });
  }

  public $httpDelete(url, tag) {
    return this.$store.dispatch("http/request", {
      tag: tag,
      url: url,
      method: "DELETE",
    });
  }
}

const routes = [
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "auth" */ "views/Login.vue"),
    name: "login",
    meta: { noAuth: true },
  },
  {
    path: "/confirmation",
    component: () =>
      import(/* webpackChunkName: "auth" */ "views/Password/Confirmation.vue"),
    name: "confirmation",
    meta: { noAuth: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "auth" */ "views/Logout.vue"),
    meta: { noAuth: true },
  },
];

export default routes;

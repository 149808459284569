import Model from "./Model";
import IFacility from "./interfaces/IFacility";
import { City } from "models";

export default class Facility extends Model implements IFacility {
  static type = "facilities";

  name: string;
  cities: City[];
  diaryCount: number;
}

import Vue from "vue";
import Vuex from "vuex";
import { http } from "./http";
import { auth } from "./auth";
import { currentUser } from "./currentUser";
import { currentPatient } from "./currentPatient";
import { doctorSettings } from "./doctorSettings";
import { diary } from "./diary";
import { forum } from "./forum";

Vue.use(Vuex);
import VuexPersist from "vuex-persist";

const vuexPersist = new VuexPersist({
  key: "titolando",
  storage: window.localStorage,
  modules: ["auth", "doctorSettings"],
});

export default new Vuex.Store({
  modules: {
    auth,
    currentUser,
    currentPatient,
    http,
    doctorSettings,
    forum,
    diary,
  },
  plugins: [vuexPersist.plugin],
});
